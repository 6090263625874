"use strict"

// Disable the dropdown-toggle on category menu items on large screens.
$('.header-menu .nav-link').on('click', function(e) {
    if ($(window).width() >= 992) {
        e.stopPropagation();
    }
});

$(function () {
    const windowWidth = $(window).width();
    // Ref for 960: https://getbootstrap.com/docs/4.0/layout/grid/#grid-options
    if (windowWidth < 960) {
        $("#navbar-menu-categories .nav-item.dropdown").each(function (_, elem) {
            $(elem).on("shown.bs.dropdown", function () {
                $("#navbar-menu-categories .nav-item.show .nav-item-back").removeClass("d-none");
                $("#navbar-menu-categories .nav-item:not(.show)").addClass("d-none");
                $("#navbar-menu-categories .nav-item.show span")
                    .removeClass("d-block")
                    .addClass("d-none");
                $(".header-contact").addClass("d-none");
            });
            $(elem).on("hidden.bs.dropdown", function () {
                $("#navbar-menu-categories .nav-item .nav-item-back").addClass("d-none");
                $("#navbar-menu-categories .nav-item.d-none").removeClass("d-none");
                $("#navbar-menu-categories .nav-item span")
                    .removeClass("d-none")
                    .addClass("d-block");
                $(".header-contact").removeClass("d-none");
            });
        });
    }
});
