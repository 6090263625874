"use strict";

import 'sly/dist/sly';
import exportedVars from 'main.scss';

const $window = $(window);

$(function () {
    const windowWidth = $window.width();
    const windowBreakpoint = parseInt(exportedVars.driftZoomBreakpoint);
    const showThumbnailsHorizontally = windowWidth < windowBreakpoint;
    $('.catalogue-gallery').each(function (i, _gallery) {
        const gallery = $(_gallery);
        const items = gallery.find('.catalogue-gallery-image, .catalogue-gallery-video');
        gallery.find('.catalogue-gallery-thumbnails-switcher').sly({
            horizontal: showThumbnailsHorizontally,
            itemNav: 'basic',
            speed: 700,
            smart: true,
            mouseDragging: 1,
            touchDragging: 1,
            releaseSwing: 1,
            backward: gallery.find('.catalogue-gallery-thumbnails-prev'),
            forward: gallery.find('.catalogue-gallery-thumbnails-next'),
            activateOn: 'mouseenter'
        }).sly('on', 'active', function (e, i) {
            // Activating the corresponding gallery item
            $(items).removeClass('active');
            $(items[i]).addClass('active');
        });
    });
});
